/* eslint-disable no-unused-vars */
import axios from "axios";
import * as dotenv from 'dotenv'
dotenv.config()

const BASE_URL = (process.env.VUE_APP_AMB === 'dev') ? process.env.VUE_APP_API_DEV : process.env.VUE_APP_API_PROD 

const formatarNomeArquivos = (files, tipo) => {
    //
    const filesName = []
    const arquivosRenomeados = []
    files.forEach((file, idx) => {
        const novoNome = `[${tipo}-${idx}] ${file.name}`
        arquivosRenomeados.push(renameFile(file, novoNome))
        filesName.push({
            nome: novoNome,
            type: file.type
        })
    });
    return {
        filesName,
        arquivosRenomeados
    }
}

const renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    })
}

const enviarArquivos = async (arraySignedPost, arrayFiles) => {
    const allPromisses = []
    arraySignedPost.forEach(
        (resp, index) => {
            const promise = new Promise((resolve, reject) => {
                const formData = new FormData();
                const file = arrayFiles.arquivosRenomeados[index]
                Object.keys(resp.fields).forEach(key => {
                    formData.append(key, resp.fields[key]);
                });
                // Actual file has to be appended last.
                formData.append("file", file);
                formData.append('Content-Type', file.type);
                const xhr = new XMLHttpRequest();
                xhr.open("POST", resp.url, true);
                xhr.send(formData);
                xhr.onload = function (response) {
                    response ? resolve() : reject();
                };
            })
            allPromisses.push(promise)
        })
    return await Promise.all(allPromisses)
}

const API = {
    async enviarForm(dados) {
        try {
            const evidencias = formatarNomeArquivos(dados.files, 'EVIDENCIA')
            const manuais = formatarNomeArquivos(dados.manuais, 'MANUTENCAO')
            dados.files = evidencias.filesName
            dados.manuais = manuais.filesName

            const resultado = await axios
                .post(`${BASE_URL}/tickets`, dados, {})

            await enviarArquivos(resultado.data.postsEvidencias, evidencias)
            await enviarArquivos(resultado.data.postsManuais, manuais)

            return {
                status: 200,
                data: {
                    id_ticket: resultado.data.id_ticket
                }
            }
        } catch (error) {
            console.log('Erro ao enviar forms', error)
            throw {
                error,
                msg: 'Erro ao enviar os arquivos'
            }
        }
    },
    async enviarSatisfacao(dados) {
        try {
            const resultado = await axios
                .post(`${BASE_URL}/satisfacao`, dados, {})
            return {
                status: 200,
                data: resultado
            }
        } catch (error) {
            console.log('Erro ao enviar forms', error)
            throw {
                error,
                msg: 'Erro ao enviar os arquivos'
            }
        }
    },
    async buscarSatisfacao(id) {
        try {
            const resultado = await axios
                .get(`${BASE_URL}/satisfacao/${id.replaceAll('#', '/')}`)
            return resultado.data.result || []
        } catch (error) {
            console.log('Erro ao enviar forms', error)
            throw {
                error,
                msg: 'Erro ao buscar satisfação'
            }
        }
    },
    async buscarSatisfacaoByEmail(email) {
        try {
            const resultado = await axios
                .get(`${BASE_URL}/satisfacao/${email}`)
            return resultado.data.result || []
        } catch (error) {
            console.log('Erro ao buscar satisfação por email', error)
            throw {
                error,
                msg: 'Erro ao buscar satisfação por email'
            }
        }
    }

}

export default API